import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types' // Import PropTypes
import { getRequest } from '../../../resources/api-request'

// Define the shape of your context data
const MyContext = createContext()

// Create a custom hook to use the context
export const useMyContext = () => {
    const context = useContext(MyContext)
    if (!context) {
        throw new Error('useMyContext must be used within a MyContextProvider')
    }
    return context
}

// Create the context provider component
export const MyContextProvider = ({ children }) => {
    const [myData, setMyData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getRequest('subscription-plans')
                setMyData(response?.data)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error)
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    return (
        <MyContext.Provider value={{ myData, loading }}>
            {loading ? (
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card" aria-hidden="true">
                                <div className="card-body">
                                    <h5 className="card-title placeholder-glow">
                                        <span
                                            className="placeholder col-6 rounded"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                    </h5>
                                    <p className="card-text placeholder-glow">
                                        <span
                                            className="placeholder col-7 rounded-start"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-4  rounded-end"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-4  rounded-start"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-6  rounded-end"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-8  rounded"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                    </p>
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <a
                                            href="#"
                                            tabIndex="-1"
                                            className="disabled placeholder col-6 rounded"
                                            style={{
                                                height: 38,
                                               backgroundColor: 'gray'
                                            }}
                                        ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" aria-hidden="true">
                                <div className="card-body">
                                    <h5 className="card-title placeholder-glow">
                                        <span
                                            className="placeholder col-6 rounded"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                    </h5>
                                    <p className="card-text placeholder-glow">
                                        <span
                                            className="placeholder col-7 rounded-start"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-4 rounded-end"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-4 rounded-start"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-6 rounded-end"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-8 rounded-"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                    </p>
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <a
                                            href="#"
                                            tabIndex="-1"
                                            className="disabled placeholder col-6 rounded"
                                            style={{
                                                height: 38,
                                                backgroundColor: 'gray'
                                            }}
                                        ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" aria-hidden="true">
                                <div className="card-body">
                                    <h5 className="card-title placeholder-glow">
                                        <span
                                            className="placeholder col-6 rounded"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                    </h5>
                                    <p className="card-text placeholder-glow">
                                        <span
                                            className="placeholder col-7 rounded-start"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-4 rounded-end"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-4 rounded-start"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-6 rounded-end"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                        <span
                                            className="placeholder col-8 rounded"
                                            style={{
                                                backgroundColor: 'gray'
                                            }}
                                        ></span>
                                    </p>
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <a
                                            href="#"
                                            tabIndex="-1"
                                            className="disabled placeholder col-6 rounded"
                                            style={{
                                                height: 38,
                                               backgroundColor: 'gray'
                                            }}
                                        ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                children
            )}
        </MyContext.Provider>
    )
}

// Add prop validation for children
MyContextProvider.propTypes = {
    children: PropTypes.node.isRequired
}
