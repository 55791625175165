import React from 'react'
import AddOnCard from './addOnCard'
import PropTypes from 'prop-types'
import { planDetailsStates } from './planDetailsStates'
import { NavLink, useNavigate } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'
import { jwtDecode } from 'jwt-decode'
import { PostRequest } from '../../resources/api-request'
import { nanoid } from '@reduxjs/toolkit'
import LogoutDropdown from '../../components/logoutDropdown'
import  { usersSvg, productSvg, outletSvg } from '../../resources/iconSVGs'

type Options = {
    feature: string
    price: string,
}
type addExtrasProps = {
    plan_id: any,
    plan_duration: any,
    subscriptionData: any,
    handleChange: (x: number, y: number) => void;
}
type Section = {
    name: string
    options: Options[]
    selected: number
    description: string
    image: string
}
interface MyJwtPayload {
    username?: string
    name?: string
}
const AddExtra = ({ subscriptionData, handleChange, plan_duration, plan_id }: addExtrasProps) => {
    const { name } = planDetailsStates()
    const token: any = localStorage.getItem('token')
    const decoded: MyJwtPayload = jwtDecode(token)
    const navigate = useNavigate();

    const logoutUser = async() => {
        const request = await PostRequest('logout', {token})
       if (request.data.message === "Logged out successfully") {
        localStorage.removeItem('token');
        navigate('/')
       }
        
    }
    return (
        <>
            <div className="col-md-5 ps-5 d-none d-md-block">
                <div className="d-flex justify-content-between mt-4 pb-5 mb-5">
                    <div />
                    <LogoutDropdown />
                </div>
                <h3 className="summary__addon--subtitle">Add Extras</h3>
                <p className="pb-1 summary__addon--text">Enhance your plan with additional features to propel your company forward.</p>
                {subscriptionData &&
                    subscriptionData.map(({ name, description, options, selected, image }: Section, x: number) => (
                        <div key={x} className="mt-4 pt-2 border-top">
                            <div className="d-flex" style={{
                                marginTop: 44,
                                marginBottom: 32
                            }}>
                                <div className="pe-3">
                                {name.includes('Users') && outletSvg}
                                {name.includes('Products') && usersSvg}
                                {name.includes('Outlets') && productSvg}
                                </div>

                                <div className="">
                                    <h5 className="summary__addon--name">{name}</h5>
                                    <p className="summary__addon--description">{description}</p>
                                </div>
                            </div>
                            <div className="row ">
                                {options.map((val: Options, y: number) => (
                                    <AddOnCard val={val} onChange={() => handleChange(x, y)} selected={selected} plan_duration={plan_duration} key={y} plan_id={plan_id}/>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </>
    )
}
AddExtra.propTypes = {
    subscriptionData: PropTypes.array.isRequired, // Assuming subscriptionData is an array
    handleChange: PropTypes.func.isRequired, // Assuming handleChange is a function
    plan_duration: PropTypes.string // Adjust according to the actual type (number, string, etc.)
}
export default AddExtra;
