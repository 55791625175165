import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getRequest } from '../../../resources/api-request'
import DownloadMobileView from './downloadCategoriesMobile'
import DownloadTabView from './downloadCategoryTab'
import VersionTable, { DownloadButton } from './downloadTable'
import SelectDropdown from '../../../components/dropdown/testDropdown'
import { postRequest } from '../../../utility/apiRequest'
import { linux, linux2 } from '../../../resources/iconSVGs'
import DownloadInfo from './downloadInfo'

type VersionType = {
    version: string
    description: string
    date: string // Ensure this is in a format that can be parsed by Date, like "YYYY-MM-DD"
    arch: { size: string; bit: string; link: string }[]
    beta: string
}

const sortVersions = (versions: VersionType[]) => {
    return versions.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
}


const DownloadCategories: React.FC = () => {
    const [tabSwitch, setTabSwitch] = useState<string>('others');
    const [activeTab, setActiveTab] = useState<'Windows' | 'Mac' | 'Linux'>('Windows')
    const [selectedDropdownItem, setSelectedDropdownItem] = useState<'Klakpad Sella'| 'Klakpad Otel'| 'Klakpad Menu'>('Klakpad Sella')
    const [selectedCategory, setSelectedCategory] = useState<'stand_alone' | 'client_server'>('stand_alone');
    const { isLoading, isError, data } = useQuery<any>('result', () => getRequest('https://api.klakpad.com/download_process.php'));
    
    const dropdownList = ['Klakpad Sella', 'Klakpad Otel', 'Klakpad Menu']
    console.log(data?.data);
    
    if (isLoading) {
        return (
            <div className="row align-items-center justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    if (isError) {
        console.log(isError)
        return <div className="text-center">Encountered an error</div>
    }

    const handleTabClick = (tabId: 'Windows' | 'Mac' | 'Linux') => setActiveTab(tabId)
   
    const handleDropdownSelect = (option: 'Klakpad Sella' | 'Klakpad Otel' | 'Klakpad Menu') => {
        setSelectedDropdownItem(option); // `option` is a string
        console.log('Selected Dropdown Option:', option);
      };

      const handleCategorySwitch = (category: 'stand_alone' | 'client_server') => {
        setSelectedCategory(category);
    };

    const versions = {
        stand_alone: {
            Windows: sortVersions(data?.data?.stand_alone?.Windows ?? []),
            Mac: sortVersions(data?.data?.stand_alone?.Mac ?? []),
            Linux: sortVersions(data?.data?.stand_alone?.Linux ?? [])
        },
        client_server: {
            Windows: sortVersions(data?.data?.client_server?.Windows ?? []),
            Mac: sortVersions(data?.data?.client_server?.Mac ?? []),
            Linux: sortVersions(data?.data?.client_server?.Linux ?? [])
        }
    }

    // const handleSwitchTab = (tab: string) => {
    //     setTabSwitch(tab)
    // }
    const navTab = [
        {
            id: 1,
            name: "Windows",
            icon2: <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 0.917725V12.8421H11.5132V2.84618L25 0.917725ZM0 12.8421H10.1974V3.03532L0 4.49503V12.8421ZM0 22.5049L10.1974 23.9646V14.1579H0V22.5049ZM11.5132 24.1537L25 26.0822V14.1579H11.5132V24.1537Z" fill="#2C4A8B"/>
            </svg>,
            icon: <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 0.917786V12.8421H11.5132V2.84624L25 0.917786ZM0 12.8421H10.1974V3.03538L0 4.49509V12.8421ZM0 22.505L10.1974 23.9647V14.1579H0V22.505ZM11.5132 24.1538L25 26.0823V14.1579H11.5132V24.1538Z" fill="#60666D"/>
            </svg>
            
        },
        {
            id: 1,
            name: "Mac",
            icon: <svg width="25" height="31" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.8298 29.1461C19.2144 30.712 17.4508 30.4647 15.753 29.723C13.9563 28.9648 12.308 28.9318 10.4125 29.723C8.03892 30.7449 6.78621 30.4482 5.36866 29.1461C-2.67507 20.8551 -1.48829 8.22906 7.64333 7.76753C9.86854 7.88291 11.418 8.98728 12.7201 9.08618C14.6651 8.69058 16.5277 7.55325 18.6046 7.7016C21.0935 7.8994 22.9726 8.88838 24.2088 10.6686C19.0661 13.7509 20.2858 20.5254 25 22.421C24.0605 24.8934 22.8407 27.3494 20.8133 29.1626L20.8298 29.1461ZM12.5553 7.66863C12.308 3.99291 15.2915 0.960025 18.72 0.66333C19.198 4.91596 14.8629 8.08071 12.5553 7.66863Z" fill="#60666D"/>
            </svg>,
            icon2: <svg width="25" height="31" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.8298 29.1461C19.2144 30.712 17.4508 30.4648 15.753 29.723C13.9563 28.9648 12.308 28.9319 10.4125 29.723C8.03892 30.745 6.78621 30.4483 5.36866 29.1461C-2.67507 20.8552 -1.48829 8.22912 7.64333 7.76759C9.86854 7.88298 11.418 8.98734 12.7201 9.08624C14.6651 8.69065 16.5277 7.55331 18.6046 7.70166C21.0935 7.89946 22.9726 8.88844 24.2088 10.6686C19.0661 13.7509 20.2858 20.5255 25 22.421C24.0605 24.8935 22.8407 27.3495 20.8133 29.1626L20.8298 29.1461ZM12.5553 7.6687C12.308 3.99297 15.2915 0.960086 18.72 0.663391C19.198 4.91602 14.8629 8.08077 12.5553 7.6687Z" fill="#2C4A8B"/>
            </svg>
            
        },
        {
            id: 1,
            name: "Linux",
            icon: linux,
            icon2: linux2
        }
    ]

    return (
        <>
            
            <div className="row justify-content-center ">
                <div className="col-auto downloads__wrapper">
                <SelectDropdown handleDropdownSelect={handleDropdownSelect} selectedDropdownItem={selectedDropdownItem} dropdownList={dropdownList}/>
                </div>
            </div>
            <div className="container">
                <div className="row">
                <DownloadInfo />
                </div>
            </div>
            {tabSwitch === 'others' ? <>
                {selectedDropdownItem === 'Klakpad Sella' ? (
                <div className="download">
                    <div className="container">
                        <DownloadMobileView />
                        {/* <DownloadTabView /> */}
                        <div className="row d-none d-lg-block ms-5 me-5 p-0">
                            <ul className="nav" id="myTabs">
                                {navTab.map((tab) => (
                                    <li key={tab.id} className="nav-item">
                                        <Link
                                            className={`nav-link downloads--link mx-3 ${activeTab === tab.name ? 'downloads--active' : ''}`}
                                            id={`${tab.name.toLowerCase()}-tab`}
                                            data-bs-toggle="tab"
                                            to={`#${tab.name.toLowerCase()}`}
                                            onClick={() => handleTabClick(tab.name as 'Windows' | 'Mac' | 'Linux')}
                                        >
                                            <div className={`d-flex align-items-center mt-2 pb-3 justify-content-center ${activeTab === tab.name ? 'downloads--activeNav' : ''}`}
                                                style={{ height: '43px', position: 'relative', right: 20 }}
                                            >
                                               <div> {activeTab === tab.name ? tab.icon2 : tab.icon}</div>
                                                <span style={{marginLeft: '14.84px'}}>{`${tab.name} Version`}</span>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <hr style={{ width: '100%', color: '#E6EBF5' }} className="border" />
                            <div className="mt-3 pt-2">
                                <VersionTable versions={versions[selectedCategory][activeTab]} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="py-5">
                    <p className="text-center">No content available for the selected category.</p>
                </div>
            )}
            </> : ''}
        </>
    )
}

export default DownloadCategories
