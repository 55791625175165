import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import paymentSummary from '../../resources/plansSummary.json'
import payment from '../../resources/paymentCard.json'
import coupons from './coupon.json'
import { useNavigate, useParams } from 'react-router-dom'
import { CiLaptop } from 'react-icons/ci'
import { FiCreditCard } from 'react-icons/fi'
import { TbWallet } from 'react-icons/tb'
import { FaArrowRight } from 'react-icons/fa6'
import { CiCircleMinus } from 'react-icons/ci'
import { PostRequest } from '../../resources/api-request'
import AddOnCard, { AddOnCardMobile } from './addOnCard'
import { planDetailsStates } from './planDetailsStates'
import { format } from '../../utility/functions'
import { updatePlanDetailsStates } from '../../store/actions/planDetails'
import { useDispatch } from 'react-redux'
import CardOptions from './cardOptions'
import { PaystackButton } from 'react-paystack'
import PayWithWallet from './payWithWallet'
import { RxCross1 } from "react-icons/rx";
import Navbar from '../../components/header/components/Navbar';
import { calculateCharges } from './calculatePaystackCharges';

type Options = {
    feature: string
    price: string
}

type Section = {
    name: string
    options: Options[]
    selected: number
    description: string
    image: string
}

const PlanDetailsMobile = ({ formattedAmount, subscriptionData, handleChange, applyCoupon, plan_duration, handleDeleteOrderSummary, handleCoupon, handlePayment, paymentMethod, paymentMethodChoices, handleRadioChange, validateCardPayment, componentProps, planDetails, agentOnbaording, subscribeFree }: any) => {

    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [addOnsOrSummaryDisplay, setAddOnsOrSummaryDisplay] = useState('addon')

    const handlePaymentProceed = () => {
        if (paymentMethod === 'wallet') {
            // Logic to trigger modal
            setIsModalVisible(true)
            // console.log('Payment method is wallet')
        } else if (paymentMethod === 'card') {
            // Logic to proceed with calling an API
        } else {
            // Optionally, handle the case where no payment method is selected
            console.log('No payment method selected.')
        }
    }
    const navigate = useNavigate();
    const {
        ordersSummary,
        plan_id,
        subAmount,
        finalTotals = 0,
        couponDiscount,
        card_type,
        saved_card,
        validation,
        payment_method,
        isOnboarding,
        finalTotal = 0,
        agent = 0,
        onboarding = 0,
        onboardingData,
        planPrice = 0
    } = planDetailsStates();


    const handleProceed = () => {
        if (ordersSummary.length !== 0) {
            console.log(subscriptionData.length)

            setAddOnsOrSummaryDisplay('summary') // Replace '/another-page' with the path of the page you want to navigate to
        }
    }

    // console.log({isModalVisible});
    return (
        <>
            <div className="row justify-content-center d-md-none d-block">
                {addOnsOrSummaryDisplay === 'summary' && (
                    <>
                        <div className="col-md-6">
                            <h5 className="mb-4 summary--title">Subscribe to {plan_id == '2' ? 'Plus' : 'Enterprise'} Plan</h5>
                            <div className="d-flex align-items-flex">
                                <span>
                                    {' '}
                                    <CiLaptop fontSize={20} className="mx-1" />
                                </span>
                                <span className="">Klakpad Retail</span>
                            </div>
                            <div className="mb-5 ">
                                <span className="plans__card--amount"> ₦{formattedAmount}</span>
                                {plan_duration == 'yearly' ? <span>/year</span> : <span>/monthly</span>}
                            </div>
                            <div className="card p-4 summary__card border-0">
                                <h5 className="summary__card--subtitle">Order Summary</h5>
                                <hr />
                                <div className="d-flex pe-4">
                                    <div className="flex-grow-1">
                                        <span className="summary__card--text">{plan_id == '2' ? 'Plus Plan' : 'Enterprise Plan'}</span>
                                    </div>
                                    <div className=" text-end summary__card--text ">
                                        <div className="d-flex align-items-center">
                                            <span className=""> ₦{formattedAmount}</span>
                                        </div>
                                    </div>
                                </div>
                                {isOnboarding && isOnboarding !== '' &&
                                    (<div className="d-flex  py-2 pe-3" >
                                        <div className="flex-grow-1">

                                            <span className="summary__card--text2"> {isOnboarding}</span>

                                        </div>
                                        <div className=" text-end summary__card--text ">
                                            <div className="d-flex align-items-center">
                                                <span className=""> ₦{new Intl.NumberFormat().format(agent)}</span>
                                                <span className="mx-1" />
                                                <span className="">
                                                    {/* <CiCircleMinus color="#2C4A8B" onClick={() => agentOnbaording('', 0, 1)} /> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>)}
                                {ordersSummary.map((val: any, index: number) => (
                                    <div className="d-flex  py-2" key={index}>
                                        <div className="flex-grow-1">
                                            <span className="summary__card--text"> {val.qty}</span>
                                            <span className="summary__card--text "> {val.name}</span>
                                        </div>
                                        <div className=" text-end summary__card--text ">
                                            <div className="d-flex align-items-center">
                                                <span className=""> ₦{new Intl.NumberFormat().format(val.price)}</span>
                                                <span className="mx-1" />
                                                <span className="">
                                                    {val.action === true ? (
                                                        <>
                                                            <CiCircleMinus color="#2C4A8B" onClick={() => handleDeleteOrderSummary(val.name, val.price)} />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {Number(planDetails?.discount) === 0 && (<div className="row">
                                    <hr />
                                    <div className="d-flex mt-4 pe-4">
                                        <input
                                            type="text"
                                            className="form-control summary__card--input shadow-none"
                                            onChange={handleCoupon}
                                            // value={discount}
                                            placeholder="Enter coupon code"
                                        />
                                        <button className=" summary__card--apply ms-2 align-items-center d-flex">
                                            <span onClick={() => applyCoupon()}>Apply</span>
                                        </button>
                                    </div>
                                </div>)}
                                <div className="row mt-3 pe-4">
                                    <hr />
                                    <div className="col-6 summary__card--text mb-4">
                                        <span>Subtotal</span>
                                    </div>
                                    <div className="col-6 text-end summary__card--text mb-4 ">₦{format(subAmount)} </div>

                                    {Number(planDetails?.discount) == 0 && (<><div className="col-6 summary__card--text">
                                        <span>Coupon Discount</span>
                                    </div>
                                        <div className="col-6 text-end summary__card--text">₦{format(couponDiscount)}</div></>)}
                                    {Number(planDetails?.discount) > 0 && (<><div className="col-6 summary__card--text">
                                        <span>Discount</span>
                                    </div>
                                        <div className="col-6 text-end summary__card--text"> ₦{plan_duration=='yearly' ? format((Number(planDetails?.discount) * planPrice) / 100) : 0}</div></>)}
                                </div>
                                <div className="row mt-3 pe-4">
                                    <div className="col-6 summary__card--text2 pb-3">
                                        <span>Order Total</span>
                                    </div>
                                    <div className="col-6 text-end summary__card--text pb-3">₦{plan_duration=='yearly' ? format(Number(subAmount) - Number(planDetails?.discount) * planPrice / 100) : format(Number(subAmount))}</div>
                                </div>
                                <div className="row mt-3 pe-4">
                                    <div className="col-6 summary__card--text">
                                        <span>Payment charges</span>
                                    </div>
                                    <div className="col-6 text-end summary__card--text">₦{format(calculateCharges(finalTotals))}</div>
                                </div>
                                <div className="row mt-3 pe-4">
                                    <hr />
                                    <div className="col-6 summary__card--text2">Total</div>
                                    <div className="col-6 text-end">
                                        <b>₦{finalTotals ? format(finalTotals + calculateCharges(finalTotals)) : 0}</b>
                                    </div>
                                </div>
                            </div>
                            <div className="card pt-5 border-0">
                                <h5 className="summary__card--subtitle">Choose onboarding options</h5>
                                <span>You can choose to get expert assistance from a Klakpad agent for setup and training</span>
                            </div>
                            {onboardingData?.map((item: any, index: number) =>
                                <div key={index} onClick={() => agentOnbaording(item.description, item.amount, item.id)} className={`card pt-3 d-flex flex-row mt-3 ${isOnboarding == item.description ? 'summary__addon--selectedcard' : 'summary__addon--unselectedcard'}`}>
                                    <div className="col-1">
                                        <div className="form-check">
                                            <input className="" type="radio" checked={isOnboarding == item.description ? true : false} name="flexRadioDefault2" id="flexRadioDefault12" />

                                        </div>
                                    </div>
                                    <div className="col-10 d-flex mb-3 ps-3">{item.description}</div>
                                </div>
                            )}
                            {/* <div onClick={agentOnbaording} className={`card pt-3 d-flex flex-row mt-3 ${isOnboarding == 'agent' ? 'summary__addon--selectedcard' : 'summary__addon--unselectedcard'}`}>
                                <div className="col-1">
                                    <div className="form-check">
                                        <input className="" type="radio"  onChange={(e: any) => handleRadioChange(e, 3, 'new_card')} checked={isOnboarding == 'agent' ? true : false} name="flexRadioDefault2" id="flexRadioDefault12" />
                                        
                                    </div>
                                </div>
                                <div className="col-10 d-flex mb-3 ps-3">Agent-assisted onboarding</div>
                            </div> */}
                            {
                                finalTotals > 0 &&
                                <div className="card pt-5 border-0 ">
                                    <h5 className="summary__card--subtitle">Payment options</h5>
                                </div>
                            }
                            <div className="row mt-3">
                                {
                                    finalTotals > 0 &&
                                    <div className="col-md-6 mb-3">
                                        <button
                                            className={`w-100 d-flex align-items-center justify-content-center summary__card--payment py-3 rounded ${paymentMethod === 'wallet' ? 'summary__addon--selectedcard' : ''}`}
                                            onClick={() => handlePayment('wallet')}
                                        >
                                            <TbWallet color="#2C4A8B" />
                                            <span className="ps-3">
                                                Pay with Wallet
                                            </span>
                                        </button>
                                    </div>
                                }
                                {
                                    finalTotals > 0 &&
                                    <div className="col-md-6 mb-3 ">
                                        <button
                                            className={`w-100 d-flex align-items-center justify-content-center summary__card--payment text-center py-3 rounded ${paymentMethod === 'card' ? 'summary__addon--selectedcard' : ''}`}
                                            onClick={() => handlePayment('card')}
                                        >
                                            <span> <FiCreditCard color="#2C4A8B" /></span>
                                            <span className="ps-3">
                                                Pay with Card
                                            </span>
                                        </button>
                                    </div>
                                }
                                {paymentMethod === 'card' && (
                                    <div className="col-md-12 mt-3">
                                        <div className="card">
                                            {paymentMethodChoices &&
                                                paymentMethodChoices.map((val: any) => (
                                                    <div
                                                        className="d-flex flex-row align-items-center summary__card--payment-options"
                                                        style={{ cursor: 'pointer' }}
                                                        key={val.id}
                                                        onClick={(e: any) => {
                                                            handleRadioChange(e, val.id, 'saved_card')
                                                            dispatch(updatePlanDetailsStates({ auth_code: val.authorization_code }))
                                                        }}
                                                    >
                                                        <CardOptions handleRadioChange={handleRadioChange} val={val} name="tops" />
                                                    </div>
                                                ))}
                                            <div className="d-flex flex-row mt-3">
                                                <div className="col-10 d-flex mb-3 ps-3">Pay with new card</div>
                                                <div className="col-2 text-center">
                                                    <div className="form-check">
                                                        <input className="" type="radio" onChange={(e: any) => handleRadioChange(e, 3, 'new_card')} checked={card_type === 'new_card' ? true : false} name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <>
                                    {
                                        card_type === "new_card" && paymentMethod === 'card' &&
                                        <div className="row mt-3 ">
                                            <span>
                                                <input
                                                    onClick={() => {
                                                        dispatch(updatePlanDetailsStates({ saved_card: saved_card === 1 ? 0 : 1 }))
                                                    }}
                                                    type="checkbox"
                                                    name=""
                                                    id=""
                                                />{' '}
                                                <span className="mx-1 summary__card--smalltext">Save Card</span>
                                            </span>
                                        </div>
                                    }
                                    {/* <div className="row mt-3">
                                    <span>
                                        <input type="checkbox" name="" id="" /> Enable autorenew of subscription
                                    </span>
                                </div> */}
                                </>
                                <div className="col-12 mt-5">
                                {paymentMethod === "card" && isOnboarding !== '' ?
                            finalTotals > 0 ?
                           
                                <PaystackButton className="w-100 py-2 summary__card--btn" {...componentProps} />
                                :
                                <button
                                className="w-100 py-2 summary__card--btn"
                                onClick={ () => subscribeFree()
                                }
                                data-bs-target={`${payment_method === 'wallet' ? '#exampleModal' : ''}`}
                                data-bs-toggle={`${payment_method === 'wallet' ? 'modal' : ''}`}
                            >
                               Subscribe for free <FaArrowRight className="mx-2" />
                            </button>
                            :
                            paymentMethod === "wallet" && isOnboarding !== '' ?
                            <button
                                className="w-100 py-2 summary__card--btn"
                                onClick={
                                    finalTotals > 0 ?
                                    handlePaymentProceed :
                                    () => subscribeFree()
                                }
                                data-bs-target={`${payment_method === 'wallet' && isOnboarding !== '' ? '#exampleModal' : ''}`}
                                data-bs-toggle={`${payment_method === 'wallet' && isOnboarding !== '' ? 'modal' : ''}`}
                            >
                                {finalTotals > 0 ? 'Proceed to payment' : 'Subscribe for free'} <FaArrowRight className="mx-2" />
                            </button>
                            :
                            <button
                                className="w-100 py-2 summary__card--btn"
                                onClick={() => alert("You have to pick a payment method and an onboarding method")}
                                // data-bs-target={`${payment_method === 'wallet' ? '#exampleModal' : ''}`}
                                // data-bs-toggle={`${payment_method === 'wallet' ? 'modal' : ''}`}
                            >
                               Proceed to payment <FaArrowRight className="mx-2" />
                            </button>}
                                </div>
                                <div className="col-12">
                                    <button
                                        className="w-100 py-2 summary__card--btn bg-transparent border-0 text-primary"
                                        onClick={() => setAddOnsOrSummaryDisplay('addon')}
                                    >
                                        Add Extras <FaArrowRight className="mx-3" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <PayWithWallet planDetails={planDetails} plan_duration={plan_duration} handleRadioChange={handleRadioChange} paymentMethodChoices={paymentMethodChoices} />
                    </>
                )}

                {addOnsOrSummaryDisplay === 'addon' && (
                    <>
                        <div className="col-md-5 d-block d-md-none">
                            <Navbar />
                            {/* <div className='mt-5 pt-4 mb-5' onClick={() => navigate('/pricing')}>
                        <RxCross1 color='#2C4A8B'/>
                        <span className='ps-2' style={{fontSize: '14px', color: '#2C4A8B', cursor: 'pointer'}}>Cancel</span>
                        </div> */}
                            <h3>Add Extras</h3>
                            <p className="pb-5 summary__addon--text">Enhance your plan with additional features to propel your company forward.</p>
                            {subscriptionData &&
                                subscriptionData.map(({ name, description, options, selected, image }: Section, x: number) => (
                                    <div key={x} className="mt-4 border-top">
                                        <div className="row mt-5">
                                            <div className="col-2">
                                                <img src={image} alt="" />
                                            </div>

                                            <div className="col-10">
                                                <h5 className="summary__addon--name">{name}</h5>
                                                <p className="summary__addon--description">{description}</p>
                                            </div>
                                        </div>
                                        <div className="summary__addon--rowsm ">
                                            {options.map((val: Options, y: number) => (

                                                <AddOnCardMobile val={val} onChange={() => handleChange(x, y)} selected={selected} plan_duration={plan_duration} key={y} />

                                            ))}
                                        </div>
                                    </div>
                                ))}
                            <div className="col-12 mt-5">
                                {
                                    ordersSummary.length > 0 ?
                                        <button className="w-100 py-2 summary__card--btnd bg-transparent text-secondary">
                                            Skip add extras <FaArrowRight className="mx-2" />
                                        </button>
                                        :
                                        <button className="w-100 py-2 summary__card--btn bg-transparent text-primary" onClick={() => setAddOnsOrSummaryDisplay('summary')}>
                                            Skip add extras <FaArrowRight className="mx-2" />
                                        </button>
                                }
                            </div>
                            <div className="col-12 mt-3">
                                <button className="w-100 py-2 summary__card--btn" role="button" aria-disabled={ordersSummary.length === 0} onClick={handleProceed}>
                                    Proceed to payment <FaArrowRight className="mx-2" />
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>

        </>
    )
}

PlanDetailsMobile.propTypes = {
    formattedAmount: PropTypes.string.isRequired,
    subscriptionData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    feature: PropTypes.string.isRequired,
                    price: PropTypes.string.isRequired,
                }).isRequired,
            ).isRequired,
            selected: PropTypes.number.isRequired,
            image: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    applyCoupon: PropTypes.func.isRequired,
    plan_duration: PropTypes.string.isRequired,
    handleDeleteOrderSummary: PropTypes.func.isRequired,
    handleCoupon: PropTypes.func.isRequired,
    handlePayment: PropTypes.func.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    paymentMethodChoices: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            authorization_code: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    handleRadioChange: PropTypes.func.isRequired,
    validateCardPayment: PropTypes.func.isRequired,
    componentProps: PropTypes.object.isRequired,
    planDetails: PropTypes.object.isRequired,
    agentOnbaording: PropTypes.object.isRequired,
    subscribeFree: PropTypes.object.isRequired,
};

export default PlanDetailsMobile
