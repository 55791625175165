import React from 'react'
import RadioButton from './radioButton'

const AddOnCard = (props: any) => {
    const { val, selected, onChange, plan_id} = props
    const { name, amount, id, qty } = val;

    // const deviceWidth  = window.innerWidth;
    // console.log({deviceWidth});
// Handle card click
const handleClick = () => {
    if (plan_id == "1") {
        alert("Upgrade Plan");
    } else {
        onChange(); // Trigger the passed onChange handler for other cases
    }
};
    return (
        <>
            <div className=" col-md-6 col-lg-4 p-2 summary__addon--col">
            <div className={`card summary__addon--card  h-100  ${selected === id ? 'summary__addon--selectedcard' : ''}`} onClick={handleClick}>
                <div className="row align-items-center mt-1 pt-1">
                    <div className="col-sm-2 col-md-4 ">
                        <RadioButton props={props} />
                    </div>
                    <div className="col-sm-10 col-md-8 pt-2">
                        <div className='pb-1'>{qty}</div>
                        <p className='summary__addon--amount'> ₦{new Intl.NumberFormat().format(amount)}</p>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}
export default AddOnCard
//

export const AddOnCardMobile = (props: any) => {
    const { val, selected, onChange } = props
    const { name, amount, id, qty } = val;

    // const deviceWidth  = window.innerWidth;
    // console.log({deviceWidth});

    return (
        <>
            <div className="p-2 summary__addon--colsm ">
            <div className={`card summary__addon--card  h-100  ${selected === id ? 'summary__addon--selectedcard' : ''}`} onClick={onChange}>
                <div className="d-flex align-items-center mt-1 pt-1">
                    <div className=" ">
                        <RadioButton props={props} />
                    </div>
                    <div className="pt-2">
                        <div className='pb-1'>{qty}</div>
                        <p className='summary__addon--amount'> ₦{new Intl.NumberFormat().format(amount)}</p>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

