import Cookies from 'js-cookie'
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ChangeNumber from './changePhone'
import { jwtDecode } from 'jwt-decode'
import { PostRequest } from '../../resources/api-request'


interface MyJwtPayload {
   phone:  any
   verification_status: any
}
interface newJwtToken {
    verification_status: any
}
const VerifyEmail: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [response, setResponse] = useState('')
    const navigate = useNavigate()
    const [userResponseType, setUserResponseType] = useState<boolean>(false)
    const length = 5
    const [otp, setOtp] = useState(new Array(length).fill(''))
    const inputsRef = useRef<(HTMLInputElement | null)[]>([])
    const [newOtpNumber, setNewOtpNumber] = useState<string>('')
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [enableOTPResend, setEnableOTPResend] = useState(false)
    const [countdown, setCountdown] = useState(59)
    const [hashedPhone, setHashValue] = useState('');

    const duration = params.get('duration')
    const plansId = params.get('plan')
    const token:any = localStorage.getItem('token')


    

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1)
            }, 1000)
        } else {
            setEnableOTPResend(true) // Enable button when countdown ends
        }

        return () => clearTimeout(timer) // Clear timeout on component unmount or countdown update
    }, [countdown])

  
   
        useEffect(() => {
            const newJwtToken: newJwtToken = jwtDecode(token);
            const validateAccountOtp = async () => {
                try {
                    const result = await PostRequest('validate-account-otp-web', {
                        function_type: 'activate-resend',
                        code: '0',
                    });
                    console.log({result});
                    
                    if (result?.data?.success) {
                        setUserResponseType(true);
                        setResponse(`${result.data.message} : please check your phone`);
                        setTimeout(() => {
                            setResponse('');
                        }, 3000);
                    }
                    if (result.data.success === false) {
                        setLoading(false)
                        setUserResponseType(false)
                        setResponse(result.data.message)
                        setTimeout(() => {
                            setResponse('')
                            setLoading(false)
                        }, 6000)
                        setResponse(result.data.message)
                    }
                    if (result.data.error === true) {
                        setLoading(false)
                        setUserResponseType(false)
                        setResponse(result.data.message)
                        setTimeout(() => {
                            setResponse('')
                            setLoading(false)
                        }, 6000)
                        setResponse(result.data.message)
                    }
                } catch (error) {
                    console.error('Error validating OTP:', error);
                }
            };

            if (newJwtToken?.verification_status !== 0 ) {
                validateAccountOtp(); // Call the async function
            }
        }, []);
    
   

    const minutes = Math.floor(countdown / 60).toString().padStart(2, "0");
  const seconds = (countdown % 60).toString().padStart(2, "0");
 
    const handleChange = (element: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = element.target.value
        // Allow only numbers
        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...otp]
            newOtp[index] = value
            setOtp(newOtp)

            // Move to next input if not the last input and current input is filled
            if (value !== '' && index < length - 1) {
                inputsRef.current[index + 1]?.focus()
            }

            // Check if all inputs are filled
            if (newOtp.every((num) => num !== '')) {
                handleComplete(newOtp.join(''))
            }

            
            
        }
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
        const { key } = event;
        if (key === 'Backspace' || key === 'Delete' && otp[index] === '' && index >= 0) {
            inputsRef.current[index - 1]?.focus();
            // Move focus backward if Backspace or Delete is pressed and not the first input
        if (key === 'Backspace' && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }

        // Optionally prevent navigation for Delete key (if required)
        if (key === 'Delete' && index < otp.length - 1) {
            inputsRef.current[index + 1]?.focus();
        }

        }
        
    };
    const handleFocus = (index: number) => {
        const current = inputsRef.current[index]
        current?.focus()
        current?.select()
    }

    useEffect(() => {
        inputsRef.current[0]?.focus()
    }, [])

    const handleComplete = (otp: string) => {
        setNewOtpNumber(otp)
        // Here you can manage what happens when the OTP is completed
        // For example, verify the OTP against a backend service
    }

    const handleOpt = async () => {
        // const storedUserData: any = Cookies.get('userData')
        // if (!storedUserData) return
        // const parsedUserData: any = JSON.parse(storedUserData)
        setLoading(true)
        // const email = parsedUserData?.email
        // const phone = parsedUserData?.phone
        const result = await PostRequest('validate-account-otp-web', { function_type: 'activate-check  ', code: newOtpNumber })
        console.log(result.data);
        
        
        if (result.data.success === true) {
           
            const newJwtToken: newJwtToken = jwtDecode(result.data.api_token);
            // console.log(newJwtToken?.verification_status, 'verification status');
            if(newJwtToken?.verification_status !=="1"){
                setUserResponseType(true)
             localStorage.setItem('token',result.data.api_token)
                setTimeout(() => {
                    setResponse(result.data.message)
                    setLoading(false)
                    // navigate(`/plans-details/${plansId}/${duration}`)
                    navigate(`/download`)
                }, 3000)
            }
        }

        if (result.data.success === false) {
            setLoading(false)
            setUserResponseType(false)
            setResponse(result.data.message)
            setTimeout(() => {
                setResponse('')
                setLoading(false)
            }, 6000)
            setResponse(result.data.message)
        }
        if (result.data.error === true) {
            setLoading(false)
            setUserResponseType(false)
            setResponse(result.data.message)
            setTimeout(() => {
                setResponse('')
                setLoading(false)
            }, 6000)
            setResponse(result.data.message)
        }
    }

    const handleResendOpt = async () => {
        setEnableOTPResend(true) 
        const storedUserData: any = Cookies.get('userData')
        const parsedUserData: any = JSON.parse(storedUserData)
        const email = parsedUserData?.email
        const phone = parsedUserData?.phone
        const result = await PostRequest('validate-account-otp-web', { function_type: 'activate-resend', code: '0' })

        if (result.data.success === true) {
            setUserResponseType(true)
            setResponse(result.data.message + ' :please check your phone')
            setTimeout(() => {
                setResponse('')
            }, 3000)
        } else {
            setResponse(result.data.message)
        }
        if (result.data.success === false || result.error === true) {
            setLoading(false)
            setUserResponseType(false)
            setResponse(result.data.message)
            setTimeout(() => {
                setResponse('')
            }, 3000)
            setResponse(result.data.message)
        }
        if (result.error === true) {
            setLoading(false)
            setUserResponseType(false)
            setResponse(result.message)
            setTimeout(() => {
                setResponse('')
            }, 6000)
            setResponse(result.message)
        }
    }

    useEffect(() => {
        const maskPhoneNumber = (number: any) => {
            // Example: +2349098765432 -> +23490******32
            const visibleDigitsStart = 5;
            const visibleDigitsEnd = 2;
      
            const start = number.slice(0, visibleDigitsStart); // Get the first 5 characters
            const end = number.slice(-visibleDigitsEnd); // Get the last 2 characters
            const masked = start + '******' + end; // Mask the middle part with asterisks
      
            return masked;
          };
          if(token){
          const decoded: MyJwtPayload = jwtDecode(token)

          setHashValue(maskPhoneNumber(decoded.phone))
          }

      }, [hashedPhone]);
    // const hashPhoneNumber = async () => {
    //     const encoder = new TextEncoder();
    //     const data = encoder.encode(decoded.phone);
    //     const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    //     const hashArray = Array.from(new Uint8Array(hashBuffer));
    //     const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    //     setHashValue(hashHex);
    //   };
    return (
        <>
            <div className="verification py-5">
                <div className="container ">
                    <div className="row justify-content-center align-items-center text-center">
                        <div
                            className="col"
                            style={{
                                marginBottom: 36
                            }}
                        >
                            <svg width="78" height="77" viewBox="0 0 78 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.790039" width="76.42" height="76.42" rx="8" fill="#F2F5FA" />
                                <path
                                    d="M20.4922 28.9559L35.6041 39.5342C36.8278 40.3908 37.4397 40.8191 38.1052 40.985C38.6931 41.1316 39.308 41.1316 39.8958 40.985C40.5614 40.8191 41.1732 40.3908 42.3969 39.5342L57.5089 28.9559M29.3762 53.0167H48.6249C51.7345 53.0167 53.2894 53.0167 54.4771 52.4115C55.5219 51.8792 56.3713 51.0298 56.9037 49.985C57.5089 48.7973 57.5089 47.2424 57.5089 44.1327V32.2874C57.5089 29.1777 57.5089 27.6228 56.9037 26.4351C56.3713 25.3903 55.5219 24.5409 54.4771 24.0086C53.2894 23.4034 51.7345 23.4034 48.6249 23.4034H29.3762C26.2665 23.4034 24.7117 23.4034 23.5239 24.0086C22.4791 24.5409 21.6297 25.3903 21.0974 26.4351C20.4922 27.6228 20.4922 29.1777 20.4922 32.2874V44.1327C20.4922 47.2424 20.4922 48.7973 21.0974 49.985C21.6297 51.0298 22.4791 51.8792 23.5239 52.4115C24.7117 53.0167 26.2665 53.0167 29.3762 53.0167Z"
                                    stroke="#2C4A8B"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <div className="verification--title">Verify your phone number</div>
                        <p className="verification--description">
                            We sent a 5 digit code to your <b> {hashedPhone} </b>
                            <br /> please enter it below.
                        </p>
                        <div className="verification--text">
                            Wrong phone number?
                            <button className="bg-transparent border-0 verification--btn"  data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <span>Change it here</span>
                            </button>
                        </div>
                        <div className="col-md-8">
                            {response && <p className={userResponseType ? 'alert alert-success' : 'alert alert-danger'}>{response}</p>}
                        </div>
                        <div className="col-md-10 ">
                            <div className=" text-center row justify-content-center">
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        ref={(ref) => (inputsRef.current[index] = ref)}
                                        type="text"
                                        maxLength={1}
                                        value={data}
                                        onChange={(e) => handleChange(e, index)}
                                        onFocus={() => handleFocus(index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        className=" verification--inp form-control shadow-none "
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="verification--footText">
                            Didn’t receive any code?{' '}
                            <b
                                onClick={enableOTPResend ? handleResendOpt : undefined} // Disable click when not allowed
                                style={{
                                    color: enableOTPResend ? '#2C4A8B' : '#b0b3b9',
                                    fontSize: 18,
                                    textDecoration: 'none',
                                    fontWeight: '600',
                                    cursor: enableOTPResend ? 'pointer' : 'not-allowed', // Show pointer if enabled, 'not-allowed' otherwise
                                    opacity: enableOTPResend ? 1 : 0.5, // Reduce opacity when disabled
                                    pointerEvents: enableOTPResend ? 'auto' : 'none', // Disable pointer events when not enabled
                                    border: 'none',
                                    borderRadius: '5px',
                                    display: 'inline-block'
                                }}
                            >
                                Resend code
                            </b>
                        </div>
                        <div style={{fontSize: 18, fontWeight: 500, letterSpacing:  -0.16, color: '#394049', marginBottom: 56}}>{minutes}:{seconds}</div>
                        <div className="col-md-5 pt-3">
                            <button className="signup__col--btn" onClick={handleOpt}>
                                {loading ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    <span> Verify phone number</span>
                                )}
                                {/* Verify phone number */}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ChangeNumber />
        </>
    )
}
export default VerifyEmail
