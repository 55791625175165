import React, {  useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getRequest } from '../../../resources/api-request'
import Tippy from '@tippyjs/react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";

type Props = {
    yearOrMonth: string
}
const PlansTable: React.FC<Props> = ({ yearOrMonth }) => {


    const { isLoading, isError, data } = useQuery<any>(['result', yearOrMonth], () => getRequest('subscription-plans'))
    const [token] = useState(localStorage.getItem('token'));
    const { pathname } = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      }, [pathname, isLoading]);
      
    const convertToCamelCase = (phrase: string) => {
        const words = phrase.split(' ')
        const camelCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        return camelCaseWords.join(' ') // Join words with spaces
    }
    const handleChoosePlan = () => { 
        if (token !== null || !token) {
            navigate(`/plans-details/${data?.data?.headers[1].id}/${yearOrMonth}`)
        } else {
            navigate(`/signup/${data?.data?.headers[1].id}/${yearOrMonth}`)
            // navigate(`/login?page=plans-details&plan_id=${2}&plan_duration=${yearOrMonth}`)
        }
    }
    const handleGetStarted = () => {
        // if (token !== null || !token) {
        //     navigate(`/plans-details/${data?.data?.headers[0].id}/${yearOrMonth}`)
        // }else{
        //     navigate('/download')
        // }
        navigate('/download')
    }

    if (isLoading) {
        return (
            <>
                {/* <h5 className='text-center'>Loading...</h5> */}
            </>
        )
    }

    if (!data?.data?.headers || !data?.data?.head_features) {
        return <div>No data available.</div>
    }

    const adjustScroll = (id:any) => {
        const top = document.getElementById(id);

        if(top){
            top.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                window.scrollBy(0, -150);
            }, 1000);
        }
    }


    return (
        <>
            <div className="plans__table d-none d-md-block">
                <div className="container">
                    <div className="row ps-4 pe-4 pb-5 mb-5">
                        <p className=" d-none d-md-block  plans__categories--title text-center mb-5 er">Compare Plan and Features</p>
                        <table className="table  d-none d-md-block py-4">
                            <thead className="border-0 ">
                                <tr>
                                    <th className="border-0 border-0 border-bottom text-start " style={{ width: '40%' }}>
                                        <div className="d-flex flex-column align-items-start justify-content-between">
                                            <div className="plans__table--header mb-4">Plans</div>
                                            <div className="plans__table--subtext"></div>
                                        </div>
                                    </th>
                                    {data?.data?.headers?.map((header:any, index:number) => (
                                        <th key={header.id} className="border-0 border-bottom">
                                            <div className="d-flex flex-column">
                                                <div className="plans__table--header">{header.name === 'Basic' ? '' : header.name}</div>
                                                <div className="plans__table--subtext">
                                                    {index === 0 && <span>{header.amountMonth === 0 ? 'Free' : ''}</span>}
                                                    {index ===1 && <span>{yearOrMonth === 'month' ? `₦ ${new Intl.NumberFormat().format(data?.data?.headers[1]?.amountMonth)}/mo` : `₦ ${new Intl.NumberFormat().format(data?.data?.headers[1]?.amountYear * data?.data?.headers[1].discount/100)}/yr`}</span>}
                                                    {index === 2 && <><span>Contact Sales</span></>}

                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className=" border-start-0">
                                <div className="d-flex flex-row py-5 ">
                                    {data?.data?.head_features.map(({ name, features }: any, index: number) => (
                                        <a onClick={() => adjustScroll(name)} className=" plans__table--menu d-flex align-items-center" key={index}>
                                            {convertToCamelCase(name)}
                                            <i className="material-symbols-outlined" style={{ fontSize: '15.33px' }}>
                                                arrow_circle_down
                                            </i>
                                        </a>
                                    ))}
                                </div>
                                {data?.data?.head_features?.map(({ name, features }: any) => (
                                    <>
                                        <tr id={`${name}`}>
                                            <th className="plans__table--subheader border-end-0">
                                                {/* <div className="d-flex pt-5" id={`${name}`}/> */}
                                                <div className="d-flex">
                                                    {convertToCamelCase(name)}
                                                </div>
                                            </th>
                                        </tr>
                                        {features.map(
                                            ({ id, features, basic, plus, enterprise, description, enterprise_value, plus_value, basic_value }: any) => {
                                                return (
                                                    <tr key={id} className="">
                                                        <td
                                                            className="plans__table--features border-bottom border-end-0 align-items-center"
                                                            style={{ width: '40%' }}
                                                        >
                                                            <span className={`d-flex`}>
                                                                {features}

                                                                <Tippy
                                                                    content={
                                                                        <div className="card border-0 shadow p-2">
                                                                            <div className="card-body p-4">
                                                                                <h5 className=" mb-3">{features}</h5>
                                                                                <p className="">{description}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    arrow={false}
                                                                    placement="bottom"
                                                                >
                                                                    <i
                                                                        className="material-symbols-outlined ps-1 pt-1"
                                                                        style={{ fontSize: '15.33px', color: '#2C4A8B' }}
                                                                    >
                                                                        info
                                                                    </i>
                                                                </Tippy>
                                                            </span>
                                                        </td>
                                                        <td className=" plans__table--text border-bottom ">
                                                            {/* {basic === 1  && <></>} */}
                                                            {basic === 1 ? (
                                                                <span>
                                                                    {basic_value === 0 ? (
                                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                          <path d="M19.9596 5.99411L8.98102 17.0022L3.99824 12.0059" stroke="#2C4A8B" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
                                                                          </svg>
                                                                    ) : (
                                                                        Intl.NumberFormat().format(basic_value)
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.93 3.93L18.07 18.07M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#B9C3D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            )}
                                                        </td>
                                                        <td className=" plans__table--text border-bottom">
                                                            {plus === 1 ? (
                                                                <span>
                                                                    {plus_value === 0 ? (
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M19.9596 5.99411L8.98102 17.0022L3.99824 12.0059" stroke="#2C4A8B" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                    ) : (
                                                                        Intl.NumberFormat().format(plus_value)
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.93 3.93L18.07 18.07M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#B9C3D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            )}
                                                        </td>
                                                        <td className=" plans__table--text border-bottom border-end-0 ">
                                                            {enterprise === 1 ? (
                                                                <span>
                                                                    {enterprise_value === 0 ? (
                                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.93 3.93L18.07 18.07M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#B9C3D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                    ) : (
                                                                        Intl.NumberFormat().format(enterprise_value)
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.93 3.93L18.07 18.07M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#B9C3D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </>
                                ))}
                            </tbody>
                            <div className="row align-items-center mt-3">
                                <div className="col-3 " />
                                <div className="col-8 ">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="col-4 ">
                                            <button className={` plans--outline `}  onClick={handleGetStarted}>
                                                Try for free
                                            </button>
                                        </div>
                                        <div className="col-4 ">
                                            <button
                                                className={` plans--btn text-white`}
                                                style={{ width: '185px' }}
                                                onClick={handleChoosePlan}
                                            >
                                                Choose plan
                                            </button>
                                        </div>
                                        <div className="col-4  d-flex justify-content-center" style={{
                                            position: 'relative',
                                            left: 35
                                        }}>
                                            <button className={`plans--outline`} >
                                                Contact sales
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PlansTable
