import React, { useContext, useState } from 'react'
import ProductPlanCard from './planCard'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../../resources/colors-contants'
import { useMyContext } from '../context'
import { jwtDecode } from 'jwt-decode'

interface wrapperProp {
    amount?: any | number
    yearOrMonth?: string
    cardMarginTop?: string
}

interface MyJwtPayload {
    verification_status: any
}

const ProductPlanCardWrapper2: React.FC<wrapperProp> = ({ amount, yearOrMonth, cardMarginTop }) => {
    console.log(amount)

    const { myData, loading } = useMyContext()
    const [token] = useState(localStorage.getItem('token'))
    const navigate = useNavigate()
    let decoded: MyJwtPayload | null = null
    if (token && token !== '') {
        decoded = jwtDecode<MyJwtPayload>(token)
    }

    const { headers } = myData

    console.log('myData', headers)
    const dat: {
        id: number
        title: string
        description: string
        priceText: any
        backgroundColor: string
        perYear: boolean
        btnBackgroundColor: string
        duration: string
        discount: string
        btnColor: string
        btnText: string
        cardBorderColor: string
        action: string
        data: { text: string }[]
        style: React.CSSProperties // Specify the type explicitly
        // ₦${myData ? headers[0].amountYear
    }[] = [
        {
            id: headers[0].id,
            title: myData ? headers[0].name : 'Basic',
            description: 'Obtain the essentials for daily operations and services, then pay just when you want to switch to a different plan.',
            priceText: `₦${new Intl.NumberFormat().format(myData ? headers[0].amountYear : '0')}.00`,
            // priceText: `₦${myData ? headers[0].amountYear : '0'}.00`,
            backgroundColor: COLORS.white_color,
            perYear: true,
            duration: yearOrMonth === 'yearly' ? 'year' : 'month',
            discount: '',
            btnBackgroundColor: COLORS.white_color,
            btnColor: COLORS.primary_background,
            btnText: 'Get started',
            cardBorderColor: '1px solid #CFD1D3',
            action: '/download',
            data: [
                { text: 'Enjoy advance level inventory management system' },
                { text: 'Track payment and purchases of unlimited customers.' },
                { text: 'Manage unlimited bank accounts & track bank transactions with ease.' }
            ],
            style: {
                backgroundColor: COLORS.white_color,
                margin: '13px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '40px',
                paddingBottom: '40px',
                boxSizing: 'border-box',
                borderRadius: '10px',
                border: '1px solid #CFD1D3',
                position: 'relative'
                // width: 499,
            }
        },
        {
            id: headers[1].id,
            title: myData ? headers[1].name : 'Plus',
            description: 'Obtain the essentials for daily operations and services, then pay just when you want to switch to a different plan.',
            priceText: `${yearOrMonth === 'yearly' ? `₦${myData ? `${new Intl.NumberFormat().format((headers[1]?.amountYear * headers[1]?.discount) / 100)}` : headers[1]?.amountMonth}` : `${new Intl.NumberFormat().format(headers[1]?.amountMonth)}`}`,
            backgroundColor: COLORS.plan_blue,
            perYear: true,
            duration: yearOrMonth === 'yearly' ? 'year' : 'month',
            discount: `${yearOrMonth === 'yearly' ? `₦${myData ? `${new Intl.NumberFormat().format(headers[1]?.amountYear)}` : ''}` : ''}`,
            btnBackgroundColor: COLORS.primary_background,
            btnColor: COLORS.white_color,
            btnText: 'Choose plan',
            cardBorderColor: 'none',
            action: `/plans-details/${headers[1].id}/${yearOrMonth}`,
            data: [
                { text: 'Enjoy advance level inventory management system' },
                { text: 'Track payment and purchases of unlimited customers.' },
                { text: 'Manage unlimited bank accounts & track bank transactions with ease.' }
            ],
            style: {
                // flex: 1,
                backgroundColor: COLORS.plan_blue,
                margin: '13px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '40px',
                paddingBottom: '40px',
                boxSizing: 'border-box',
                borderRadius: '10px',
                border: 'none',
                position: 'relative'
                // width: 499,
            }
        },
        {
            id: headers[2].id,
            title: myData ? headers[2]?.name : 'Enterprise',
            description:
                'Get the optimal price structure for your typical transaction size, payment volume, and other business requirements by partnering with us.',
            priceText: 'Personalise pricing',
            backgroundColor: COLORS.white_color,
            perYear: false,
            duration: '',
            discount: '',
            btnBackgroundColor: COLORS.white_color,
            btnColor: COLORS.primary_background,
            btnText: 'Contact sales',
            cardBorderColor: '1px solid #CFD1D3',
            action: '/contact',
            data: [
                { text: 'Contact sales to get your customized pricing that suits your business.' }
                // { text: 'Track payment and purchases of unlimited customers.' },
                // { text: 'Manage unlimited bank accounts & track bank transactions with ease.' }
            ],
            style: {
                // flex: 1,
                backgroundColor: COLORS.white_color,
                margin: '13px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '40px',
                paddingBottom: '40px',
                boxSizing: 'border-box',
                borderRadius: '10px',
                border: '1px solid #CFD1D3',
                position: 'relative'
                // width: 499,
            }
        }
    ]

    return (
        <>
            <div className=" d-block d-lg-none" style={{ marginTop: cardMarginTop || '1rem' }}>
                <div className="row justify-content-center" id="planCardLayout">
                    {dat.map((val, index) => (
                        <div className={`col-12 col-md-6  ${!dat[dat.length] ? 'mb-5' : ''} `} key={index} style={val.style}>
                            <ProductPlanCard
                                title={val.title}
                                description={val.description}
                                priceText={val.priceText}
                                backgroundColor={val.backgroundColor}
                                perYear={val.perYear}
                                discount={val.discount}
                                duration={val.duration}
                                btnBackgroundColor={val.btnBackgroundColor}
                                btnColor={val.btnColor}
                                btnText={val.btnText}
                                cardBorderColor={val.cardBorderColor}
                                btnAction={() => {
                                    if (token && decoded?.verification_status === 0) {
                                        navigate(val.action)
                                    }
                                    if (token && decoded?.verification_status === 0 && val.id === headers[0].id ) {
                                        navigate('download')
                                    }
                                    if (token && decoded?.verification_status === 1) {
                                        navigate(`/verify-phone?plan=${val.id}&duration=${yearOrMonth}`)
                                    }
                                    if (!token) {
                                        navigate(`/signup?plan=${val.id}&duration=${yearOrMonth}&page=signup`)
                                    }
                                    if (val.action === '/contact') {
                                        navigate(val.action);
                                    }

                                }}
                                arr={val.data}
                                isStyled={true}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ProductPlanCardWrapper2
